<template>
  <div>dfiles - {{info}}</div>
</template>
<script>
  import { mapState } from 'vuex'
  export default {
    data () {
      return {
        info: ''
      }
    },
    computed: {
      ...mapState('app',['dfilesWorkspace']),
    },
    watch: {
      dfilesWorkspace () {
        this.handleGetData()
      }
    },
    mounted () {
      this.handleGetData()
    },
    methods: {
      handleGetData () {
        this.info = this.dfilesWorkspace
      }
    }
  }
</script>